import React from 'react';
import RoundedLinkButton from '~components/buttons/RoundedLinkButton/RoundedLinkButton';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { getRegisterButtonProps } from '~utils/buttontext';
import useConversionFramework from '~hooks/useConversionFramework';

const RegisterButton = props => {
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable, marketingUser } = useUserContext().session;
  const registerButtonProps = getRegisterButtonProps(jwtAvailable, isMarketingSite, marketingUser);

  return (
    <RoundedLinkButton
      text={registerButtonProps.text}
      to={registerButtonProps.url}
      shouldTrackAnalytics={registerButtonProps.shouldTrackAnalytics}
      analyticsEvent={registerButtonProps.analyticsEvent}
      {...props}
    />
  );
};

export default RegisterButton;
